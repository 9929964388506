<template>
  <div class="words-card-container">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="words-card-page-box">

      <div class="title-area" v-if="titleInfo" :class="'lesson-' + lesson">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="word-cards-content" :class="{'small-layout': layout == 'small', 'small-medium-layout': layout == 'small-medium'}">
        <div
          class="word-card-item"
          :class="'lesson-' + lessonNum"
          v-for="(item, index) in wordCardList"
          :key="index + 'cards'"
        >
          <!-- 无图片 -->
          <div
            class="frontage-content"
            v-if="!item.isShowImg"
            :class="{
              redCoverColor: item.bgColor == 'redColor',
              yellowCoverColor: item.bgColor == 'yellowColor',
              black: item.bgColor == 'yellowColor',
              'teacher-hover': item.currentNum === 0,
              one: item.order == 'one',
              one2: item.order == 'one2',
              two: item.order == 'two',
              three: item.order == 'three',
              four: item.order == 'four',
              five: item.order == 'five',
              five2: item.order == 'five2',
              six: item.order == 'six',
              blue: item.blue,
            }"
            @click.stop="clickImage(index, !item.isShowImg)"
          >
            <div
              class="frontage-area"
              :class="{
                'long-text': item.longtext,
              }"
            >
              <div v-if="item.bgColor == 'redColor'">
                <p
                  class="pinyin"
                  :class="[
                    item.pinyinClass ? item.pinyinClass : 'font-pinyin-large',
                  ]"
                >
                  {{ item.pinyin }}
                </p>
                <p
                  class="secp"
                  :class="[
                    item.hanziClass ? item.hanziClass : 'font-hanzi-large',
                    item.pinyinWrap ? 'wrapText' : '',
                  ]"
                >
                  {{ item.hanzi }}
                </p>
              </div>
              <p
                :class="[item.bgColor == 'yellowColor' ? 'secp' : '', item.englishClass ? item.englishClass : 'font-english-large', item.englishFontFamily == 'SourceHanSans' ? 'font-SourceHanSans': '' ]"
              >
                {{ item.english }}
              </p>
            </div>
          </div>
          <!-- 有图片 -->
          <div
            class="contray-content one"
            v-else
            :class="{
              redColor: item.bgColor == 'redColor',
              yellowColor: item.bgColor == 'yellowColor',
              'teacher-hover': item.currentNum === 0,
              one: item.order == 'one',
              one2: item.order == 'one2',
              two: item.order == 'two',
              three: item.order == 'three',
              four: item.order == 'four',
              five: item.order == 'five',
              five2: item.order == 'five2',
              six: item.order == 'six',
            }"
            @click.once="clickImage(index, !item.isShowImg)"
          >
            <img :src="item.image" alt="" v-if="!item.Showing" />
            <img :src="item.imgShow" alt=""  v-else/>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  components: {
    PageButton,
  },
  props: {
    wordCardList: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonNum:{
      type: Number,
      require: false,
    },
    titleInfo:{
      type: Object,
      require: false,
    },
    layout:{
      type: String,
      require: false
    }
  },
  data() {
    return {
      clickImageValue: false,
      steps: 0,
      currentCardInfo: {
        index: null,
        isShowImg: null,
      },
      imgListen: require(`@/assets/img/05-Panda/page-01-listen.svg`),
      imgLook: require(`@/assets/img/05-Panda/page-01-look.svg`),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },

        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 6,
            numBerValueAfterClick: null,
          },
        ],
      },
    };
  },
  watch: {
    currentCardInfo: {
      handler(value) {
        if (!this.clickImageValue) {
          const data = {
            clickType: 20007,
            data: { value },
            text: "wordCardsPage点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickSingleCard", ({ index, isShowImg }) => {
      this.clickImage(index, isShowImg, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("wordsCardPage");
    this.$bus.$off("clickSingleCard")
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    clickImage(index, isShowImg, isFromSocket=false) {
      if (!isFromSocket) {
        this.clickImageValue = false;
      } else {
        this.clickImageValue = true;
      }
      if (!isShowImg) {
        playCorrectSound(true, false);
        ++this.steps;
      } else {
        --this.steps;
      }
      if(this.lessonNum===18){
        this.wordCardList[index].Showing=true
      this.isLastStep = this.steps === this.wordCardList.length ? true : false;
        if (this.isLastStep == true) {
        playCorrectSound();
        startConfetti();
      }
      }else{
      this.currentCardInfo.index = index;
      this.currentCardInfo.isShowImg = isShowImg;
      this.$emit("changeDisplay", index, isShowImg);
      this.isLastStep = this.steps === this.wordCardList.length ? true : false;
      if (this.isLastStep == true) {
        playCorrectSound();
        startConfetti();
      }
      }
      
    },
  },
};
</script>
<style lang="scss" scoped>

.words-card-container {
  width: 100%;
  height: 100%;
  &.chapter-wordcard {
    background-color: #fff;
  }
}
.words-card-page-box {
  background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
    no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 10px;
      top: 10%;
      width: 18%;
      height: 17%;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
     
    }

  .word-cards-content {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &.small-layout {
      .word-card-item {
        flex: 16% 0 0;
      }
    }
    &.small-medium-layout {
      .word-card-item {
        flex: 18% 0 0;
      }
    }

    .word-card-item {
      width: 29%;
      display: flex;
      flex-direction: column;
      margin: 0 2%;
      .frontage-area {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 70%;
        &.long-text {
          width: 100%;
          .secp {
            //to do : smaller font size
            font-size: 35px;
          }

          .wrapText {
            white-space: normal;
            line-height: 37px;
          }
        }
        .font-english-medium {
          line-height: 20px;
        }
      }
      .secp {
        line-height: 88px;
        white-space: nowrap;

        &.font-SourceHanSans {
          font-family: 'SourceHanSans' !important;
        }

        // very large screen
        font-size: 80px;
        @media screen and (max-width: 2200px) {
          // external screen
          font-size: 60px;
        }
        @media screen and (max-width: 1500px) {
          // laptop
        }
        @media screen and (max-width: 1300px) {
          // ipad
          font-size: 45px;
        }
      }
      p {
        line-height: 30px;
      }
      .frontage-content {
        width: 100%;
        position: relative;
        height: 0;
        padding-bottom: 100%;
        background: #214e96;
        border-radius: 18px;
        text-align: center;
        color: #fff;
        margin-bottom: 10%;
        border-bottom: 15px solid #a53d32;
        cursor: pointer;
        &:hover {
          border-bottom: 15px solid #f6c752;
        }
      }
      .redCoverColor {
        background: #214e96;
        border-bottom-color: #a53d32;
      }
      .redColor {
        background: #ce4c3f;
        border-bottom-color: #a53d32;
      }
      .yellowCoverColor {
        background: #dec32c;
        border-bottom-color: #f4d737;
      }
      .yellowColor {
        background: #f4d737;
        border-bottom-color: #dec32c;
      }
      .contray-content {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 10%;
        // background: #ce4c3f;
        position: relative;
        border-radius: 18px;
        // border-bottom: 15px solid #a53d32;
        border-bottom-width: 15px;
        border-bottom-style: solid;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
          border-bottom: 15px solid #f6c752;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          margin-left: 20%;
          width: 60%;
        }
      }

      .one {
        transform: rotate(6deg);
      }
      .one2 {
        transform: rotate(-6deg);
        margin-top: 20%;
      }
      .two {
        transform: rotate(-2deg);
      }
      .three {
        transform: rotate(0deg);
      }
      .four {
        transform: rotate(7deg);
      }
      .five {
        transform: rotate(6deg);
      }
      .five2 {
        transform: rotate(6deg);
        margin-top: 20%;
      }
      .six {
        transform: rotate(-5deg);
      }
      .blue {
        background: #fff !important;
        color: #214e96 !important;
      }
      .black {
        background: #dec32c !important;
        color: #000 !important;
        font-size: 100px;
      }
    }
    .lesson-11 {
      width: 29%;
    }
  }
}

.teacher-hover {
  opacity: 0.8;
}
</style>
