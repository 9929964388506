var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "words-card-container" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _c("div", { staticClass: "words-card-page-box" }, [
        _vm.titleInfo
          ? _c(
              "div",
              { staticClass: "title-area", class: "lesson-" + _vm.lesson },
              [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "word-cards-content",
            class: {
              "small-layout": _vm.layout == "small",
              "small-medium-layout": _vm.layout == "small-medium",
            },
          },
          _vm._l(_vm.wordCardList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "cards",
                staticClass: "word-card-item",
                class: "lesson-" + _vm.lessonNum,
              },
              [
                !item.isShowImg
                  ? _c(
                      "div",
                      {
                        staticClass: "frontage-content",
                        class: {
                          redCoverColor: item.bgColor == "redColor",
                          yellowCoverColor: item.bgColor == "yellowColor",
                          black: item.bgColor == "yellowColor",
                          "teacher-hover": item.currentNum === 0,
                          one: item.order == "one",
                          one2: item.order == "one2",
                          two: item.order == "two",
                          three: item.order == "three",
                          four: item.order == "four",
                          five: item.order == "five",
                          five2: item.order == "five2",
                          six: item.order == "six",
                          blue: item.blue,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clickImage(index, !item.isShowImg)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "frontage-area",
                            class: {
                              "long-text": item.longtext,
                            },
                          },
                          [
                            item.bgColor == "redColor"
                              ? _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "pinyin",
                                      class: [
                                        item.pinyinClass
                                          ? item.pinyinClass
                                          : "font-pinyin-large",
                                      ],
                                    },
                                    [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "secp",
                                      class: [
                                        item.hanziClass
                                          ? item.hanziClass
                                          : "font-hanzi-large",
                                        item.pinyinWrap ? "wrapText" : "",
                                      ],
                                    },
                                    [_vm._v(" " + _vm._s(item.hanzi) + " ")]
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "p",
                              {
                                class: [
                                  item.bgColor == "yellowColor" ? "secp" : "",
                                  item.englishClass
                                    ? item.englishClass
                                    : "font-english-large",
                                  item.englishFontFamily == "SourceHanSans"
                                    ? "font-SourceHanSans"
                                    : "",
                                ],
                              },
                              [_vm._v(" " + _vm._s(item.english) + " ")]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "contray-content one",
                        class: {
                          redColor: item.bgColor == "redColor",
                          yellowColor: item.bgColor == "yellowColor",
                          "teacher-hover": item.currentNum === 0,
                          one: item.order == "one",
                          one2: item.order == "one2",
                          two: item.order == "two",
                          three: item.order == "three",
                          four: item.order == "four",
                          five: item.order == "five",
                          five2: item.order == "five2",
                          six: item.order == "six",
                        },
                        on: {
                          "~click": function ($event) {
                            return _vm.clickImage(index, !item.isShowImg)
                          },
                        },
                      },
                      [
                        !item.Showing
                          ? _c("img", { attrs: { src: item.image, alt: "" } })
                          : _c("img", {
                              attrs: { src: item.imgShow, alt: "" },
                            }),
                      ]
                    ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }